













































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {
  AdManagementDto,
  CreateOrUpdateAdManagementDto,
  GetListAdManagementDto
} from '@/framework/models/AdManagementDto';
import AdManagementApi from '@/api/system/AdManagement';
import {upload} from '@/api/file/upload';

@Component({})
export default class AdManagement extends Vue {
  private api = new AdManagementApi();
  private listQuery = new GetListAdManagementDto();
  private tableData: PageResultDto<AdManagementDto> = {
    items: new Array<AdManagementDto>(),
    totalCount: 0
  };

  private upload = {
    action: '',
    imageUrl: ''
  }

  private uploadData: any | undefined;

  private dialog = {
    title: '',
    visible: false
  }

  private dataForm: CreateOrUpdateAdManagementDto = {
    fileUrl: '',
    site: undefined,
    orderNumber: undefined,
  }

  private rules = {
    fileUrl: [{
      required: true,
      message: '图片地址不能为空',
      trigger: 'blur'
    }],
  }

  private async activated() {
    await this.getList();
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private handleFaceAvatarSuccess(file: any) {
    this.upload.imageUrl = URL.createObjectURL(file.raw);
    this.uploadData = new FormData();
    this.uploadData.append('file', file.raw);
  }


  private beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      this.$message.error('图片只能是 JPG|PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
  }

  private uploadHttpRequest() {
    upload(this.uploadData).then((res) => {
      if (res) {
        this.dataForm.fileUrl = res.fullName;
        this.handelSave();
      }
    });
  }

  private handleCreate() {
    this.resetForm();
    this.dialog.title = '新增';
    this.dialog.visible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private updateHandle(row: any) {
    this.resetForm();
    this.dialog.visible = true;
    this.dialog.title = '编辑';
    this.dataForm = Object.assign({}, row);

    if (row.fileUrl) {
      this.upload.imageUrl = `${row.fileUrl}`;
    }

    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private resetForm() {
    this.dataForm = {
      fileUrl: '',
      site: undefined,
      orderNumber: undefined,
    }

    this.upload.imageUrl = ''
  }

  private handelConfirm() {
    if (this.uploadData) {
      this.uploadHttpRequest();
    }

    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }
      if (!this.uploadData) {
        await this.handelSave();
      }
    });
  }

  private async handelSave() {
    if (this.dialog.title === '新增') {
      await this.creating(this.dataForm);
    } else if (this.dialog.title === '编辑') {
      await this.editing(this.dataForm);
    }
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList();
    this.close();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList();
    this.close();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      await this.api.DeleteAsync(row.id);
      await this.getList();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  private close() {
    this.dialog.visible = false;
  }
}
