import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface AdManagementDto extends ExtensibleEntityDto <Guid> {
  fileUrl: string
  site: number
  orderNumber: number
}

export class CreateOrUpdateAdManagementDto {
  public fileUrl: string = ''
  public site: number | undefined
  public orderNumber: number | undefined
}

export class GetListAdManagementDto extends PagedAndSortedResultRequestDto {
  public site?: number
}
